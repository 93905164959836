import { ColorSchema } from "src/theme/palette";

type AllowedTypes = number | boolean | null;

export const determineColor = (
	value: AllowedTypes,
	kind: "type" | "status" | "absenceApprovalStatus" | "task_priority"
) => {
	let result = "";
	switch (kind) {
		case "type": {
			result = parseType(value);
			break;
		}
		case "status": {
			result = parseStatus(value);
			break;
		}
		case "absenceApprovalStatus": {
			result = parseAbsenceApprovalStatus(value);
			break;
		}
		case "task_priority": {
			result = parseTaskPriority(value);
			break;
		}
		default: {
			result = "error";
			break;
		}
	}
	return result as ColorSchema;
};

const parseType = (value: AllowedTypes) => {
	if (typeof value !== "number") return "error";
	switch (value) {
		case 1: {
			return "info";
		}
		case 2: {
			return "warning";
		}
		case 3: {
			return "success";
		}
		default: {
			return "error";
		}
	}
};

const parseStatus = (value: AllowedTypes) => {
	if (typeof value !== "number") return "error";
	switch (value) {
		case 1: {
			return "info";
		}
		case 2: {
			return "warning";
		}
		case 3: {
			return "success";
		}
		default: {
			return "error";
		}
	}
};

const parseAbsenceApprovalStatus = (value: AllowedTypes) => {
	if (typeof value === "number") return "error";
	switch (value) {
		case null: {
			return "warning";
		}
		case true: {
			return "success";
		}
		case false: {
			return "error";
		}
		default: {
			return "info";
		}
	}
};

const parseTaskPriority = (value: AllowedTypes) => {
	switch (value) {
		case 10: {
			return "green";
		}
		case 9: {
			return "orange";
		}
		case 8: {
			return "red";
		}
		default: {
			return "success";
		}
	}
};
