import { useUser } from "@clerk/nextjs";
import {
	Checkbox,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Tooltip,
	Typography,
	Stack,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import Iconify from "@components/other/Iconify";
import useResponsive from "src/hooks/useResponsive";
import { determineColor } from "src/utils/determineColor";
import { fDate } from "src/utils/formatTime";
import { UPDATE_ASSIGNED_TASK_COMPLETION_BY_PK, UPDATE_PERSONAL_TASK_COMPLETION_BY_PK } from "../TaskGraphql";
import { WidgetTask } from "../../../type/widgetTask";
import { useMutation } from "urql";

type TaskWidgetGroupProps = {
	group: {
		name: string;
		id: number;
	};
	hideCompleted: boolean;
	tasks: WidgetTask[];
	onTaskUpdate: (tasks: UpdatedTaskGroup) => void;
};

export type UpdatedTaskGroup = {
	id: number;
	tasks: WidgetTask[];
};

const TaskWidgetGroup = (props: TaskWidgetGroupProps) => {
	const [tasks, setTasks] = useState<WidgetTask[]>(props.tasks);
	const { user } = useUser();
	const isDesktop = useResponsive("up", "md");

	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		setTasks(props.tasks);
	}, [props.tasks]);

	const [
		{ data: updatePersonalTaskCompletionData, error: updatePersonalTaskCompletionError },
		updatePersonalTaskCompletion,
	] = useMutation<any>(UPDATE_PERSONAL_TASK_COMPLETION_BY_PK);

	useEffect(() => {
		if (updatePersonalTaskCompletionData) {
			const response = updatePersonalTaskCompletionData.update_workflow_user_task_by_pk;
			updateCompletedOnTask(response.id, response.updated_at, response.completed_on ?? null);
		}
	}, [updatePersonalTaskCompletionData]);

	useEffect(() => {
		if (updatePersonalTaskCompletionError) {
			enqueueSnackbar(`Er is een fout opgetreden bij het wijzigen van de taak. Probeer het later opnieuw.`, {
				variant: "error",
			});
		}
	}, [updatePersonalTaskCompletionError]);

	const [
		{ data: updateAssignedTaskCompletionData, error: updateAssignedTaskCompletionError },
		updateAssignedTaskCompletion,
	] = useMutation<any>(UPDATE_ASSIGNED_TASK_COMPLETION_BY_PK);

	useEffect(() => {
		if (updateAssignedTaskCompletionData) {
			const response = updateAssignedTaskCompletionData.update_workflow_user_workflow_task_by_pk;
			updateCompletedOnTask(response.task_id, response.updated_at, response.completed_on ?? null);
		}
	}, [updateAssignedTaskCompletionData]);

	useEffect(() => {
		if (updateAssignedTaskCompletionError) {
			enqueueSnackbar(`Er is een fout opgetreden bij het wijzigen van de taak. Probeer het later opnieuw.`, {
				variant: "error",
			});
		}
	}, [updateAssignedTaskCompletionError]);

	const updateCompletedOnTask = (task_id: number, updated_at: Date, completed_on: Date | null) => {
		const deepCopy: WidgetTask[] = JSON.parse(JSON.stringify(tasks));
		const task = deepCopy.find((row: WidgetTask) => row.id === task_id);
		if (!task) return;
		task.completed_on = completed_on;
		enqueueSnackbar("Taak gewijzigd");
		const updatedGroupObject: UpdatedTaskGroup = {
			id: props.group.id,
			tasks: deepCopy,
		};
		props.onTaskUpdate(updatedGroupObject);
	};

	const handleCheckChange = (taskID: number, checked: boolean, type: "personal" | "assigned") => {
		const completed_on = checked ? new Date() : null;
		if (type === "personal") {
			return updatePersonalTaskCompletion({
				id: taskID,
				completed_on,
			});
		}
		return updateAssignedTaskCompletion({
			task_id: taskID,
			user_id: user?.id,
			completed_on,
		});
	};

	return (
		<List sx={{ width: "100%", bgcolor: "background.paper" }}>
			{tasks
				.filter((row) =>
					props.hideCompleted ? row.completed_on === null : row.completed_on === null || row.completed_on !== null
				)
				.map((task: WidgetTask, index: number) => (
					<ListItem
						key={task.id}
						disablePadding
						secondaryAction={
							<Stack direction={"row"} gap={isDesktop ? 1 : 0.5}>
								<Tooltip arrow title={task.priorityByPriority.name}>
									<span>
										<Iconify
											sx={{ display: "flex", color: determineColor(task.priority, "task_priority") }}
											icon="la:exclamation-triangle"
											width={20}
											height={20}
										/>
									</span>
								</Tooltip>
								{!isDesktop && (
									<Tooltip arrow title={`${task.deadline ? fDate(new Date(task.deadline)) : "-"}`}>
										<span>
											<Iconify sx={{ display: "flex" }} icon="la-clock" width={20} height={20} />
										</span>
									</Tooltip>
								)}
								{isDesktop && (
									<Typography variant="body2">
										{task.deadline === null ? "-" : fDate(new Date(task.deadline))}
									</Typography>
								)}
							</Stack>
						}
					>
						<ListItemButton dense>
							<ListItemIcon>
								<Checkbox
									onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
										handleCheckChange(task.id, event.target.checked, task.user_id === null ? "assigned" : "personal");
									}}
									edge="start"
									checked={task.completed_on !== null}
								/>
							</ListItemIcon>
							<ListItemText primary={task.title} />
						</ListItemButton>
					</ListItem>
				))}
			{tasks.length === 0 && (
				<Typography variant="body2">{"U heeft geen persoonlijke taken voor deze week!"}</Typography>
			)}
			{tasks.length > 0 &&
				props.hideCompleted &&
				tasks.filter((row) => row.completed_on !== null).length === tasks.length && (
					<Typography variant="body2">{"U heeft alle wekelijkse taken voor deze groep afgerond!"}</Typography>
				)}
		</List>
	);
};

export default TaskWidgetGroup;
