import { Card, CardContent, CardHeader, IconButton, Stack, Tooltip } from "@mui/material";
import NextLink from "next/link";
import { ReactNode } from "react";
import Iconify from "@components/other/Iconify";

type Props = {
	children?: ReactNode;
	actions?: ReactNode;
	link?: Link;
	headerTitle: string;
};

type Link = {
	title: string;
	icon: string;
	href: string;
	disabled: boolean;
};

const Widget = ({ children, actions, headerTitle, link }: Props) => (
	<Card>
		<CardHeader
			title={headerTitle}
			action={
				<Stack gap={3}>
					{actions}
					{link && (
						<IconButton disabled={link.disabled}>
							<Tooltip arrow title={link.title}>
								<span>
									<NextLink href={link.href} passHref>
										<span>
											<Iconify icon={link.icon} />
										</span>
									</NextLink>
								</span>
							</Tooltip>
						</IconButton>
					)}
				</Stack>
			}
		/>
		<CardContent>{children}</CardContent>
	</Card>
);

export default Widget;
