import Masonry from "@mui/lab/Masonry";
import TaskWidgetComponent from "../task/widget/TaskWidgetComponent";

export default function WidgetOverview() {
	return (
		<Masonry columns={{ xs: 1, md: 2 }} spacing={2} sx={{ maxWidth: 1 }}>
			<TaskWidgetComponent />
		</Masonry>
	);
}
