import { useUser } from "@clerk/nextjs";
import { Box, Container, Grid } from "@mui/material";
import { GetStaticProps } from "next";
import { useTranslation } from "next-i18next";
import { ReactElement } from "react";
import AppWelcome from "@components/other/AppWelcome";
import Page from "@components/other/Page";
import useSettings from "@hooks/useSettings";
import Layout from "src/layouts";
import { getTranslation } from "@utils/i18n";
import { useLottie } from "lottie-react";
import checklist from "../src/assets/lotties/checklist.json";
import WidgetOverview from "@features/dashboard/WidgetOverview";

export const getStaticProps: GetStaticProps = async function getStaticProps({ locale }) {
	const translations = await getTranslation(locale, ["common", "home"]);
	return {
		props: {
			...translations,
		},
	};
};

Home.getLayout = function getLayout(page: ReactElement) {
	return <Layout>{page}</Layout>;
};

export default function Home() {
	const { themeStretch } = useSettings();
	const { user } = useUser();
	const { t } = useTranslation("pages");
	const { View, setSpeed } = useLottie({
		animationData: checklist,
	});

	return (
		<Page title={t("title")}>
			<Container maxWidth={themeStretch ? false : "xl"}>
				<Grid container spacing={3}>
					<Grid item xs={12} md={12}>
						<AppWelcome
							title={`Welkom terug! \n ${user?.fullName}`}
							// description="HRFlow is een platform dat je helpt om je HR-processen te automatiseren en te optimaliseren."
							description="HRFLow is a platform that helps you to automate and optimize your HR processes."
							id="test"
							img={<Box sx={{ p: 3, width: 360, margin: { xs: "auto", md: "inherit" } }}>{View}</Box>}
						/>
					</Grid>
				</Grid>
				<Box sx={{ pt: 5 }}>
					<WidgetOverview />
				</Box>
			</Container>
		</Page>
	);
}
