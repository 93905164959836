import { useUser } from "@clerk/nextjs";
import { yupResolver } from "@hookform/resolvers/yup";
import { ListItem, ListItemText, Stack, Switch, Tooltip } from "@mui/material";
import { endOfWeek, format, startOfWeek } from "date-fns";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FormProvider, RHFAutocomplete } from "src/components/hook-form";
import Scrollbar from "@components/other/Scrollbar";
import { ROUTES } from "src/layouts/routes";
import { GET_USER_TASKS_BY_DEADLINE_WEEK } from "../TaskGraphql";
import TaskWidgetGroup, { UpdatedTaskGroup } from "./TaskWidgetGroup";
import { GlobalWidgetWorkflowTask, TaskWidgetGroupProps } from "../../../type/widgetTask";
import { useQuery } from "urql";
import Widget from "@components/other/Widget";
import { TaskWidgetSchema } from "../TaskSchema";

type Group = {
	id: number;
	name: string;
};

type Form = {
	group?: Group;
};

const TaskWidgetComponent = () => {
	const { user } = useUser();
	const [checked, setChecked] = useState(true);
	const [groups, setGroups] = useState<Group[]>([]);

	const [tasks, setTasks] = useState<TaskWidgetGroupProps[]>([]);

	const methods = useForm<Form>({
		mode: "onSubmit",
		resolver: yupResolver(TaskWidgetSchema),
		defaultValues: {
			group: {
				id: 0,
				name: "Personal",
			},
		},
	});
	const { watch } = methods;

	const activeGroup = watch();

	const toggleChecked = (event: any) => {
		setChecked(event.target.checked);
	};

	const [{ data: userTasksByDeadlineWeekData, error: usersError }] = useQuery<any>({
		query: GET_USER_TASKS_BY_DEADLINE_WEEK,
		variables: {
			user_id: user?.id,
			start_of_week: format(startOfWeek(new Date()), "yyyy-MM-dd"),
			end_of_week: format(endOfWeek(new Date()), "yyyy-MM-dd"),
		},
	});

	useEffect(() => {
		if (userTasksByDeadlineWeekData) {
			const personalTasks: TaskWidgetGroupProps = {
				group: {
					name: "Personal",
					id: 0,
				},
				tasks: userTasksByDeadlineWeekData.personal_tasks,
			};
			const globalWorkFlowTasks: GlobalWidgetWorkflowTask[] =
				userTasksByDeadlineWeekData.global_workflows.user_workflow_tasks;
			const globalWorkFlowResponse: TaskWidgetGroupProps[] = [];
			for (const element of globalWorkFlowTasks) {
				const index = globalWorkFlowResponse.findIndex(
					(element_) => element_.group.id === element.task.workflow_tasks[0].workflow.id
				);
				if (index === -1) {
					globalWorkFlowResponse.push({
						group: {
							name: element.task.workflow_tasks[0].workflow.name,
							id: element.task.workflow_tasks[0].workflow.id,
						},
						tasks: [
							{
								completed_on: element.completed_on,
								created_at: element.task.created_at,
								deadline: element.deadline_date,
								id: element.task.id,
								priority: element.task.priority,
								priorityByPriority: element.task.priorityByPriority,
								start_date: element.start_date,
								title: element.task.title,
								user_id: null,
							},
						],
					});
				} else {
					globalWorkFlowResponse[index].tasks.push({
						completed_on: element.completed_on,
						created_at: element.task.created_at,
						deadline: element.deadline_date,
						id: element.task.id,
						priority: element.task.priority,
						priorityByPriority: element.task.priorityByPriority,
						start_date: element.start_date,
						title: element.task.title,
						user_id: null,
					});
				}
			}
			const allTasks = [personalTasks, ...globalWorkFlowResponse];
			setTasks(allTasks);
			const allGroups = allTasks.map((element: TaskWidgetGroupProps) => element.group);
			setGroups(allGroups);
		}
	}, [userTasksByDeadlineWeekData]);

	const handleTaskGroupUpdate = (groupTasks: UpdatedTaskGroup) => {
		const deepCopy: TaskWidgetGroupProps[] = JSON.parse(JSON.stringify(tasks));
		const index = deepCopy.findIndex((element) => element.group.id === groupTasks.id);
		if (index < 0) return;
		deepCopy[index].tasks = groupTasks.tasks;
		setTasks(deepCopy);
	};

	return (
		<Widget
			headerTitle="Uw taken voor deze week"
			link={{
				title: "Toon alle taken",
				disabled: activeGroup.group?.id === undefined,
				href: ROUTES.tasks.group(Number(activeGroup.group?.id)),
				icon: "la-external-link-alt",
			}}
		>
			<Stack height={1} gap={1}>
				<FormProvider methods={methods}>
					<ListItem disablePadding>
						<ListItemText
							primary={
								<RHFAutocomplete
									size="small"
									autoComplete
									autoHighlight
									isOptionEqualToValue={(option, value) => option.id === value.id}
									disableClearable
									fullWidth
									name="group"
									options={groups}
									getOptionLabel={(option) => (option as Group).name}
									label="Groep"
									disablePortal={false}
								/>
							}
						/>
						<Tooltip arrow title="afgeronde workflows verbergen">
							<Switch size="small" checked={checked} onChange={toggleChecked} />
						</Tooltip>
					</ListItem>
				</FormProvider>
				<Scrollbar sx={{ maxHeight: 200 }}>
					{tasks
						.filter((element) => element.group.id === activeGroup?.group?.id)
						.map((task: TaskWidgetGroupProps, index: number) => (
							<TaskWidgetGroup
								onTaskUpdate={handleTaskGroupUpdate}
								hideCompleted={checked}
								key={index}
								group={task.group}
								tasks={task.tasks}
							/>
						))}
				</Scrollbar>
			</Stack>
		</Widget>
	);
};

export default TaskWidgetComponent;
