import { object, number, string, date } from "yup";

export const TaskWidgetSchema = object().shape({
	group: object()
		.shape({
			id: number().required(),
			name: string().required(),
		})
		.required()
		.nullable()
		.label("Groep"),
});

export const TaskDetailSchema = object().shape({
	title: string().required().nullable().label("Titel"),
	comment: string().optional().nullable().label("Omschrijving"),
	start_date: date()
		.typeError("Startdatum moet een geldige datum zijn")
		.min(0)
		.optional()
		.nullable()
		.label("Startdatum"),
	deadline: date().typeError("Deadline moet een geldige datum zijn").min(0).optional().nullable().label("Deadline"),
	priorityByPriority: object()
		.shape({
			id: number().required(),
		})
		.required()
		.nullable()
		.label("Prioriteit"),
});

export const PersonalTaskSchema = object().shape({
	title: string().required().nullable().label("Titel"),
	comment: string().optional().nullable().label("Omschrijving"),
	start_date: date()
		.typeError("Startdatum moet een geldige datum zijn")
		.min(0)
		.optional()
		.nullable()
		.label("Startdatum"),
	deadline: date().typeError("Deadline moet een geldige datum zijn").min(0).optional().nullable().label("Deadline"),
	priorityByPriority: object()
		.shape({
			id: number().required(),
		})
		.required()
		.nullable()
		.label("Prioriteit"),
});
