import { gql } from "urql";

export const GET_USER_TASKS_BY_USER_ID = gql`
	query GET_USER_TASKS_BY_USER_ID($user_id: String!) {
		workflow_user_task(where: { user_id: { _eq: $user_id } }, order_by: { order: asc }) {
			user_id
			id
			title
			order
			created_at
			comment
			start_date
			deadline
			priority
			priorityByPriority {
				name
				id
				comment
			}
			completed_on
			updated_at
			created_by: user {
				full_name
			}
		}
	}
`;

export const GET_PRIORITIES = gql`
	query GET_PRIORITIES {
		priority(order_by: { id: asc }) {
			id
			name
			comment
		}
	}
`;

export const GET_WORKFLOW_TASKS_BY_WORKFLOW_ID = gql`
	query GET_WORKFLOW_TASKS_BY_WORKFLOW_ID($user_id: String!, $workflow_id: Int!) {
		workflow_user_workflow_task(
			where: { user_id: { _eq: $user_id }, task: { workflow_tasks: { workflow_id: { _eq: $workflow_id } } } }
		) {
			task_id
			assigned_by: user {
				full_name
			}
			completed_on
			assigned_at
			updated_at
			start_date
			deadline_date
			task {
				id
				workflow_tasks {
					order
				}
				created_at
				updated_at
				start_days
				deadline_days
				comment
				updated_by: userByUpdatedBy {
					full_name
				}
				title
				priority
				priorityByPriority {
					id
					name
					comment
				}
				created_by: user {
					full_name
				}
			}
		}
	}
`;

export const GET_USER_TASKS_BY_DEADLINE_WEEK = gql`
	query GET_USER_TASKS_BY_DEADLINE_WEEK($user_id: String!, $start_of_week: timestamptz!, $end_of_week: timestamptz!) {
		personal_tasks: workflow_user_task(
			where: { user_id: { _eq: $user_id }, deadline: { _gte: $start_of_week, _lte: $end_of_week } }
			order_by: { deadline: asc }
		) {
			user_id
			id
			title
			created_at
			start_date
			deadline
			priority
			completed_on
			priorityByPriority {
				name
				id
				comment
			}
		}
		global_workflows: employee_user_by_pk(id: $user_id) {
			user_workflow_tasks(
				where: { deadline_date: { _gte: $start_of_week, _lte: $end_of_week } }
				distinct_on: task_id
			) {
				deadline_date
				start_date
				completed_on
				task {
					id
					title
					created_at
					priority
					priorityByPriority {
						id
						name
						comment
					}
					workflow_tasks {
						workflow {
							id
							name
						}
					}
				}
			}
		}
	}
`;

export const GET_WORKFLOWS_BY_USER_ID = gql`
	query GET_WORKFLOWS_BY_USER_ID($user_id: String) {
		workflow_workflow(where: { workflow_tasks: { task: { user_workflow_tasks: { user_id: { _eq: $user_id } } } } }) {
			id
			name
		}
		unfinished_workflows: workflow_workflow_task(
			where: { task: { user_workflow_tasks: { user_id: { _eq: $user_id }, completed_on: { _is_null: true } } } }
			distinct_on: workflow_id
		) {
			workflow_id
			workflow {
				name
			}
		}
	}
`;

export const UPDATE_PERSONAL_TASK_COMPLETION_BY_PK = gql`
	mutation UPDATE_PERSONAL_TASK_COMPLETION_BY_PK($id: Int!, $completed_on: timestamptz!) {
		update_workflow_user_task_by_pk(pk_columns: { id: $id }, _set: { completed_on: $completed_on }) {
			id
			updated_at
			completed_on
		}
	}
`;

export const UPDATE_ASSIGNED_TASK_COMPLETION_BY_PK = gql`
	mutation UPDATE_ASSIGNED_TASK_COMPLETION_BY_PK($completed_on: timestamptz!, $task_id: Int!, $user_id: String!) {
		update_workflow_user_workflow_task_by_pk(
			pk_columns: { task_id: $task_id, user_id: $user_id }
			_set: { completed_on: $completed_on }
		) {
			task_id
			updated_at
			completed_on
		}
	}
`;

export const DELETE_USER_TASK_BY_PK = gql`
	mutation DELETE_USER_TASK_BY_PK($id: Int!) {
		delete_workflow_user_task_by_pk(id: $id) {
			id
		}
	}
`;

export const UPDATE_PERSONAL_TASK_ORDER = gql`
	mutation UPDATE_PERSONAL_TASK_ORDER($objects: [workflow_user_task_insert_input!]!) {
		insert_workflow_user_task(objects: $objects, on_conflict: { constraint: user_task_pkey, update_columns: order }) {
			affected_rows
		}
	}
`;

export const UPDATE_PERSONAL_USER_TASK_BY_PK = gql`
	mutation UPDATE_PERSONAL_USER_TASK_BY_PK($id: Int!, $_set: workflow_user_task_set_input!) {
		update_workflow_user_task_by_pk(pk_columns: { id: $id }, _set: $_set) {
			id
			user_id
			title
			created_at
			completed_on
			created_by: user {
				full_name
			}
			updated_at
			comment
			deadline
			start_date
			priorityByPriority {
				id
				name
				comment
			}
			priority
		}
	}
`;

export const INSERT_USER_TASK = gql`
	mutation INSERT_USER_TASK($object: workflow_user_task_insert_input!) {
		insert_workflow_user_task_one(object: $object) {
			user_id
			id
			title
			created_at
			comment
			start_date
			deadline
			priority
			order
			priorityByPriority {
				name
				id
				comment
			}
			completed_on
			updated_at
			created_by: user {
				full_name
			}
		}
	}
`;
